.section{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-size: 50%;
    background-position: bottom 0px right 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    bottom: 0;
    z-index: 1;
    transition: background 0.5s linear;
    
}

.content{
    color: white;
    padding: 50px;
    position: relative;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.container .section .content h1{
    font-size: 2.5em;
    font-weight: bolder;
    text-align: center;
}

.container .section .content h2{
    font-size: 1.2em;
    font-weight: 800;
    background-color: white;;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background 0.5s linear;
    transform: scale(1);
    cursor: pointer;
    transition:transform 2s
}

/* @media screen and (max-width: 600px){
    .container .section .content h1{
        font-size: 2.5em;
        font-weight: bolder;
        text-align: center;
        padding: 0;
    }
}
 */

