.section-eggmarket {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    bottom: 0;
    z-index: 1;
    transition: background 0.5s linear;
}
img {
    padding: 0px;
    margin: 0px;
}
.content-eggmarket {
    color: white;
    padding: 10px;
    position: relative;
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    transform: translateY(5%);
}
.container .section .content h1 {
    font-size: 2.5em;
    font-weight: bolder;
    text-align: center;
}
.container .section .content h2 {
    font-size: 1.2em;
    font-weight: 800;
    background-color: white;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background 0.5s linear;
    transform: scale(1);
    cursor: pointer;
    transition: transform 2s;
}
.top-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    /* background-color: rgba(255, 255, 255, 0.2); */
    min-height: 60px;
    width: 100%;
    border-radius: 33px;
    margin-bottom: 10px;
}
.search-content {
    overflow: hidden;

    flex-direction: row;
    width: 100%;
    /* height: 90%; */
}
.sidebar {
    margin-right: 10px;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.2);
    height: 100%;
    width: 205px;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.cards-container {
    flex-grow: 1;
    overflow-y: scroll;
    /* width: 100%; */
    height: 100%;
    border-radius: 24px;
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: flex-start; */

    /* flex-wrap: wrap;
    align-content: flex-start; */
}
.gridCards {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.nft__Card {
    margin: 0px 0.5rem 1rem 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    width: 15%;
}
.nft__Card:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
.nft__Img {
    border-radius: 1.1rem;
    margin: 0px;
    width: 100%;
    height: auto;
}
.nft__infoContainer {
}
.nft__title {
    font-size: medium;
    margin: -5px 0px 0px 0px;
    padding: 0px;
}
.nft__infoPrice {
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
}
.nft__priceLabel {
    font-weight: 500;
    margin: 0px;
    padding: 0px;
}
.nft__buy {
    margin: 0px;
    padding: 0px;
}
.hideSidebar {
    background-color: rgba(255, 255, 255, 0.15);
    padding: 1rem;
    border-radius: 2rem;
    margin-right: 1rem;
    cursor: pointer;
}
.hideSidebar:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
input {
    -webkit-appearance: none;
    outline: none;
}
.topbar__input {
    background-image: url("./search-13.png");
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: 1rem;
    width: 40%;
    margin-right: 1rem;
    font-size: medium;
    border-radius: 2rem;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
}
input:focus-visible {
    border: 0px;
}
.topbar__input::placeholder {
    color: white;
    opacity: 0.5;
}
#Search::-webkit-search-cancel-button {
    visibility: hidden;
    /* padding-right: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    opacity: 1;
    -webkit-appearance: none;
    display: inline-block;
    margin-left: 10px;
    background: url("./times-circle.svg");
    background-repeat: no-repeat;
    background-position: left 50%; */
}
.topbar__tokenInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}
.heart {
    position: relative;
}
