.order-list-wrapper{
    width: 100%;
    background-color: #969174;
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex-basis: 100%;
    justify-content: flex-start;
    align-items: center;
    border-radius: 19px;
}
/* .order-list-wrapper p{
    padding: 10px;
    padding-left: 10px;
} */

.order-list-top{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #aca78a;
    border-radius: 19px;
    padding: 9px;
    padding-left: 10px;
    text-align: start;
}
.order-list-item-remove-menu{
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}
