.header{
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
}
.nav{
    font-size: small;
    background-color: rgba(255, 254, 254, 0.2);
    position: absolute;
    width: 65vw;
    margin-top: 10px;
    height: fit-content;
    padding: 10px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    color: #000;
    top: 0;
    z-index: 999;
    /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);  */  
}

.dropdown{
    cursor: pointer;
    display: block;
    position: absolute;
    right: 0;
    top:0;
    transform: scale(1,0);
    transform-origin: top;
    transition: transform 200ms ease-in-out;
}

.nav ul{
    color: white;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.nav ul li{
    text-transform: uppercase;
    padding: 0 10px;
    list-style-type: none;
    transition: transform .2s;
    transition:transform 2s
}


header .nav ul .icon{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    padding: 10px;
}

.nav ul a{
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.logo{
    fill: white;
}

.header-icon{
    padding:10px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.35);
    cursor: pointer;
}
/* .header-dropdown{
    position: absolute;
    right: 6.5%;
    top: 100%;
    transform-origin: top;
    transition: transform 200ms ease-in-out;
    display: flex;
    flex-direction: column;
} */

.header-dropdown{
    width: 65%;
    position: absolute;
    top: 100%;
    transform-origin: top;
    transition: transform 200ms ease-in-out;
    display: flex;
    flex-direction: column;
}

.header-dropdown ul{
    margin: 0px;
    padding: 9px;
    text-align: right;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    margin-top: 10px;
    align-content: center;
}

/* .header-dropdown ul li{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: fit-content;
    margin-top: 10px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 35%);
} */

.header-dropdown ul li{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: fit-content;
    padding: 10px;
}


.header-dropdown.active{
    transform: scale(1,1);
}
.header-dropdown.inactive{
    transform: scale(1,0);
}

.socials{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.gap{
    margin-left: 10px;
}
.separator{
    width: 1px;
    height: 30px;
    background-color: white;
    opacity: 0.3;
}
