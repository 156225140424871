.order-list-item{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 9px;
    height: 38px;
}
.order-list-item:hover{
    background-color: #aca78a;
    border-radius: 19px;
}

.order-list-wrapper p{
    padding-left: 5px;
}

.item-number{
    width: 6%;
    font-size: small;
    text-align: center;
}
.status{
    padding: 5px;
    width: 1%;
    height: 20px;
    border-radius: 10px;
}
.status-color-waiting{
    background-color: orange;
}
.status-color-filled{
    background-color: #69c053;
}
.status-color-failed{
    background-color: #dd4646;
}

.status-label{
    width: 14%;
}
.order-list-item-remove{
    background-repeat: no-repeat;
    background-size: 15px;
    width: 50px;
    height: 15px;
    cursor: pointer;
    text-align: end;
    background-position: right;
    background-image: url('./close-button.webp');
}
.order-list-item-status-label{
    width: 12%;
    font-size: small;
}
.order-list-item-network{
    width: 14%;
    font-size: small;
}
.order-list-item-tx{
    width: 8%;
    font-size: small;
}
.order-list-item-desc{
    width: 50%;
    font-size: small;
}