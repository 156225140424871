@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}
#root {
    width: 100vw;
    height: 100vh;
}
html,
body {
    margin: 0;
    height: 100%; /* overflow: hidden;  overflow-y: scroll;*/
} /*prevent mobile screen from hidding url bar*/
body {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
body.dark {
    background-color: #101f11;
}
.container {
    background-color: #3d5757;
    position: relative;
    /* width: 100vw; */
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
}
button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*-----------------------MEDIA QUERY----------------*/

@media screen and (max-width: 600px) {
    .section:nth-child(1),
    .section:nth-child(2),
    .section:nth-child(3),
    .section:nth-child(4),
    .section:nth-child(5) {
        background-size: 100%;
    }
    .section {
        justify-content: center;
    }
    .content {
        width: 100vw;
        justify-content: center;
        align-items: center;
        transform: translateY(-10%);
        text-align: center;
    }
    .nav {
        width: 95vw;
    }
    .nav ul a li {
        padding: 0 5px;
    }
    .rangeSlider {
        width: 100%;
    }
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        /* width: 70px; */
    }
    .header-dropdown {
        right: 0%;
    }
    .header-dropdown ul {
        margin: 0px;
        padding: 0px;
        text-align: right;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-around;
    }
    .header-dropdown ul {
        margin-top: 8px;
        padding: 5px;
    }
    .header-dropdown ul li {
        width: auto;
        justify-content: center;
    }
    .slider {
        width: 90%;
    }
    .header-dropdown {
        width: 100%;
    }
    .separator {
        display: none;
    }
    .padding-25 {
        padding: 25px;
    }

    .item-number {
        width: 10%;
        font-size: small;
    }
    .status {
        padding: 0;
        width: 2%;
        height: 20px;
        border-radius: 10px;
    }
    .status-color-waiting {
        background-color: orange;
    }
    .status-color-filled {
        background-color: #69c053;
    }
    .status-color-failed {
        background-color: #dd4646;
    }

    .status-label {
        width: 14%;
    }

    .order-list-item-status-label {
        width: 20%;
        font-size: small;
    }
    .order-list-item-network {
        width: 14%;
        font-size: small;
    }
    .order-list-item-tx {
        padding: 0;
        width: 8%;
        font-size: small;
        text-align: start;
    }
    .order-list-item-desc {
        text-align: start;
        width: 40%;
        font-size: small;
    }
    .order-list-item-remove {
        background-repeat: no-repeat;
        background-size: 15px;
        width: 50px;
        height: 15px;
        cursor: pointer;
        text-align: end;
        background-position: center;
        background-image: url("./close-button.webp");
    }
}
