.footer{
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 999;
    color: white;
    margin-bottom: 10px;
}
.footer-container{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.alert{
    background: red;
}
.left-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px;
    font-size: small;
    border-radius: 50px;
    /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35); */
    transform: scale(1);
    cursor: pointer;
}
.right-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px;
    font-size: small;
    border-radius: 50px;
    /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35); */
    transform: scale(1);
    cursor: pointer;
}
.right-footer p{
    padding-left: 10px;
    padding-right: 10px;
}
.left-footer p{
    padding-left: 10px;
    padding-right: 10px;
}
.footer-icon{
    padding:10px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.35);
}
.right-footer-table{
    position: absolute;
    right: 0px;
    bottom:100%;
    margin-right: 10px;
    transform-origin: bottom;
    transition: transform 200ms ease-in-out;
}
.right-footer-table ul {
    margin: 0px;
    padding: 0px;
    text-align: right;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.right-footer-table ul li{
    display: flex;
    width: fit-content;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.3);
    /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.85);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35); */
}
.right-footer-table ul li span{
    font-size: small;
}
.right-footer-table.active{
    transform: scale(1,1);
}
.right-footer-table.inactive{
    transform: scale(1,0);
}