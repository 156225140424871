.section-team {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-size: 50%;
    background-position: bottom 0px right 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    bottom: 0;
    z-index: 1;
    transition: background 0.5s linear;
}

.content-team {
    color: white;
    padding: 50px;
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.container .section .content-team h1 {
    font-size: 2.5em;
    font-weight: bolder;
    text-align: center;
}

.container .section .content-team h2 {
    font-size: 1.2em;
    font-weight: 800;
    background-color: white;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background 0.5s linear;
    transform: scale(1);
    cursor: pointer;
    transition: transform 2s;
}
.team-container {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;
    width: 100%;
    justify-content: center;
}
.team-desc {
    width: 90%;
}
.team-member {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    margin: 1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 16%;
    border-radius: 2rem;
}
.team-member__image {
    width: auto;
    border-radius: 1.5rem;
}
.team-member__title {
    padding: 0.5rem 1rem 0rem 1rem;
}
.team-member__subtitle {
    padding-bottom: 1rem;
}
.team-member__desc {
    padding: 0px;
    font-size: small;
}

/*Modal*/

.overlay {
    background-color: rgb(226 173 76);
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 80%;
    height: 80%;
}
.modalContent {
    display: flex;
    flex-direction: row;
    max-width: 800px;
}
.modalContent__image {
    width: 400px;
    height: 400px;
}
.modalContent__info {
    display: flex;
    flex-direction: column;
}
p {
    font-size: 0.95rem;
}
span {
}
img {
    border-radius: 2rem;
}
h2 {
    padding: 0;
    padding-left: 0.5rem;
}
h4 {
    padding: 0.5rem 0rem 0.5rem 0rem;
    font-weight: 400;
}
.modalContent__info {
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
}
.goBackArrow {
    margin: 1rem;
    width: 1.1rem;
    height: 1.1rem;
    border-left: 3px solid white;
    border-bottom: 3px solid white;
    transform: rotate(45deg);
    position: absolute;
    left: 5%;
    top: 13%;
    cursor: pointer;
}
.memberTitles {
    display: flex;
    flex-direction: row;
}
.memberTitles__subtitle {
    font-weight: 400;
    font-size: x-large;
    padding-right: 0.5rem;
    border-right: 4px solid white;
}

@media only screen and (max-width: 900px) {
    .team-container {
        flex-wrap: nowrap;
        width: 100%;
        overflow-y: hidden;
    }
    .team-container::-webkit-scrollbar {
        height: 6px;
    }
    .team-member {
        margin: 0.5rem;
        width: 26%;
        transform: translateX(160px);
    }
}
@media only screen and (max-width: 800px) {
    .modalContent {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .modalContent__info {
        align-items: center;
        width: 80%;
    }
    .modalContent__image {
        display: contents;
    }
    .modalContent__image img {
        width: 300px;
        height: 300px;
    }
}

@media only screen and (max-width: 700px) {
    .modalContent {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .modalContent__info {
        align-items: center;
        width: 80%;
    }
    .modalContent__image {
        display: contents;
    }
    .modalContent__image img {
        width: 250px;
        height: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .team-container {
        flex-wrap: nowrap;
        width: 100%;
    }
    .team-member {
        margin: 0.4rem;
        width: 35%;
        transform: translateX(235px);
        justify-content: space-between;
    }
    .team-member__image {
        width: auto;
        border-radius: 1.5rem;
    }
    .team-container::-webkit-scrollbar {
        height: 6px;
    }
    .memberTitles {
        flex-direction: column;
        align-items: center;
    }
    .memberTitles__subtitle {
        padding-right: 0px;
        border-right: 0;
    }
}
@media only screen and (max-width: 425px) {
    .team-member {
        margin: 0.4rem;
        width: 45%;
        transform: translateX(252px);
        justify-content: space-between;
    }
    .team-container {
        flex-wrap: nowrap;
        width: 100%;
        overflow-y: hidden;
    }
    .team-container::-webkit-scrollbar {
        height: 6px;
    }
    .overlay {
        flex-direction: column;
    }
    .modalContent {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .modalContent__info {
        align-items: center;
        width: 100%;
    }
    .modalContent__image {
        display: contents;
    }
    .modalContent__image img {
        width: 150px;
        height: 150px;
    }
    .modalContainer {
        height: auto;
    }
}
