.section{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-size: 50%;
    background-position: bottom 0px right 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    bottom: 0;
    z-index: 1;
    transition: background 0.5s linear;
    
}

.content{
    color: white;
    padding: 30px;
    position: relative;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.container .section .content h1{
    font-size: 2.5em;
    font-weight: bolder;
    text-align: center;
}

.container .section .content h2{
    font-size: 1.2em;
    font-weight: 800;
    background-color: white;;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background 0.5s linear;
    transform: scale(1);
    cursor: pointer;
    transition:transform 2s
    
}
.slider{
    width: 50%;
    padding-top: 50px;
}
.preset-buttons-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 10px;
}
.button-preset{
    width: 40px;
    height: 40px;
    margin: 5px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    font-weight: bold;
}
h3{
    padding: 5px;
}
.stats-wrapper{
    margin-top: 10px;
    margin-bottom: 10px;
}
h1{
    padding: 10px;
    margin: 5px;
}

@media screen and (max-width: 600px){
    .container .section .content h1{
        font-size: 2em;
        font-weight: bolder;
        text-align: center;
        line-height: 2.8rem;
    }
}
